import {ID} from '@datorama/akita';
import {Membership} from './package';
import {dateTimeFromServer} from '../../shared/utils/moment';
import {Moment} from 'moment-timezone';

export interface UserAuthInfoFromServer {
	token?: string;
	user_id?: ID
}

export interface UserAuthInfo {
	token?: string;
	userId?: ID
}

export interface UserPaymentFromServer {
	membership: Membership;
	paid_on: string;
	product: string;
}

export interface UserPayment {
	membership: Membership;
	paidOn: Moment;
	product: string;
}

export interface UserSubscription {
	newsletter: boolean;
	tutorial: boolean;
}

export interface UserFromServer {
	id: number;
	first_name: string;
	last_name: string;
	name: string;
	email: string;
	photo: string;
	phone: string;
	bio: string;
	bio_html: string;
	web: string;
	// for user
	facebook_link: string;
	twitter_link: string;
	linkedIn_link: string;
	// for speaker
	facebook: string;
	twitter: string;
	linkedIn: string;
	instagram: string;
	position: string;
	company: string;
	country: string;
	language: string;
	secret_number: number;
	last_events: number[];
	email_language: string;
	thumbnail: string;
	fb_user: number;
	tw_user: number;
	li_user: number;
	is_anonymous: boolean;
	role: string;
	pivot: {
		role: string;
	};
	pending: boolean;
	imported: boolean;
	appsumo: boolean;
	payment: UserPaymentFromServer;
	default_company_id: number;
	email_valid: boolean;
	order: number;
	registered_at: string;
    subscribed: boolean;
	invited?: boolean;
	email_invalid?: boolean;
	group_id?: number;
	anonymous?: boolean
}

export interface User {
	id: ID;
	firstName: string;
	lastName: string;
	name: string;
	email: string;
	photo: string;
	phone: string;
	bio: string;
	web: string;
	facebook: string;
	twitter: string;
	linkedIn: string;
	instagram: string;
	position: string;
	company: string;
	country: string;
	language: string;
	secretNumber: number;
	lastEvents: number[];
	emailLanguage: string;
	thumbnail: string;
	fbUser: number;
	twUser: number;
	liUser: number;
	isAnonymous: boolean;
	userType: UserTypes;
	role: UserRoles;
	roleType: UserRoleTypes;
	pending: boolean;
	imported: boolean;
	appsumo: boolean;
	payment?: UserPayment;
    defaultCompanyId?: number;
	emailValid: boolean;
	order: number;
	registeredAt?: Moment;
	subscribed: boolean;
	invited?: boolean;
	emailInvalid?: boolean;
	groupId?: number;
	status?: UserStatus
}

export enum UserTypes {
	ATTENDEE = 'attendee',
	MODERATOR = 'moderator',
	VISITOR = 'visitor',
	ADMIN = 'admin',
	SPEAKER = 'SPEAKER',
}

export enum UserRoles {
	OWNER = 'o',
	ATTENDEE = 'e',
	VISITOR = 'v',
	MODERATOR = 'm',
	ADMIN = 'a'
}

export enum UserRoleTypes {
	OWNER,
	ADMIN,
	ADMIN_PENDING,
	MODERATOR,
	MODERATOR_PENDING,
	ATTENDEE,
	ATTENDEE_PENDING,
	ATTENDEE_IMPORTED,
	VISITOR
}

export enum UserStatus {
	ANONYMOUS = 'anonymous',
	REGISTERED = 'registered',
	EMAIL_INVALID = 'email_invalid',
	PENDING = 'pending',
	NOT_INVITED = 'not_invited'
}

export enum UserMenuItems {
	ATTENDEES = 'attendees',
	SPEAKERS = 'speakers',
	PARTNERS = 'partners',
	TEAM = 'team',
}

export function getRoleString(role: UserRoles) {
	switch (role) {
		case UserRoles.OWNER:
			return 'GENERAL.ROLES.OWNER';
		case UserRoles.ADMIN:
			return 'GENERAL.ROLES.ADMIN';
		case UserRoles.MODERATOR:
			return 'GENERAL.ROLES.MODERATOR';
		case UserRoles.ATTENDEE:
			return 'GENERAL.ROLES.ATTENDEE';
		case UserRoles.VISITOR:
			return 'GENERAL.ROLES.VISITOR';
	}
}

export function getRoleTypeString(role: UserRoleTypes) {
	switch (role) {
		case UserRoleTypes.OWNER:
			return 'GENERAL.ROLES.OWNER';
		case UserRoleTypes.ADMIN:
			return 'GENERAL.ROLES.ADMIN';
		case UserRoleTypes.MODERATOR:
			return 'GENERAL.ROLES.MODERATOR';
		case UserRoleTypes.ATTENDEE:
			return 'GENERAL.ROLES.ATTENDEE';
		case UserRoleTypes.VISITOR:
			return 'GENERAL.ROLES.VISITOR';
		case UserRoleTypes.ADMIN_PENDING:
		case UserRoleTypes.MODERATOR_PENDING:
		case UserRoleTypes.ATTENDEE_PENDING:
			return 'GENERAL.ROLES.PENDING';
		case UserRoleTypes.ATTENDEE_IMPORTED:
			return 'GENERAL.ROLES.IMPORTED';
	}
}

export function getRoleColor(role: UserRoleTypes) {
	switch (role) {
		case UserRoleTypes.OWNER:
		case UserRoleTypes.ADMIN:
		case UserRoleTypes.ADMIN_PENDING:
			return 'badge-danger';
		case UserRoleTypes.MODERATOR:
		case UserRoleTypes.MODERATOR_PENDING:
			return 'badge-secondary';
		case UserRoleTypes.ATTENDEE:
		case UserRoleTypes.ATTENDEE_PENDING:
		case UserRoleTypes.ATTENDEE_IMPORTED:
			return 'badge-success';
		default:
			return 'badge-gray';
	}
}

export function convertRoleToUserType(role: UserRoles) {
	switch (role) {
		case UserRoles.ADMIN:
			return UserTypes.ADMIN;
		case UserRoles.MODERATOR:
			return UserTypes.MODERATOR;
		case UserRoles.ATTENDEE:
			return UserTypes.ATTENDEE;
		case UserRoles.VISITOR:
			return UserTypes.VISITOR;
	}
}

export function getUserRoleType(role: string, pending: boolean, imported: boolean) {
	switch (role) {
		case UserRoles.OWNER:
			return UserRoleTypes.OWNER;
		case UserRoles.ADMIN:
			return (pending ? UserRoleTypes.ADMIN_PENDING : UserRoleTypes.ADMIN);
		case UserRoles.MODERATOR:
			return (pending ? UserRoleTypes.MODERATOR_PENDING : UserRoleTypes.MODERATOR);
		case UserRoles.ATTENDEE:
			if (pending) {
				return (imported ? UserRoleTypes.ATTENDEE_IMPORTED : UserRoleTypes.ATTENDEE_PENDING);
			}
			return UserRoleTypes.ATTENDEE;
		case UserRoles.VISITOR:
			return UserRoleTypes.VISITOR;
	}
}

export function isAdmin(role: UserRoles) {
	return role === UserRoles.ADMIN || role === UserRoles.OWNER;
}

export function isModerator(role: UserRoles) {
	return isAdmin(role) || role === UserRoles.MODERATOR;
}

export function getUserStatus(emailInvalid: boolean, anonymous:boolean, registeredAt: Moment, invited: boolean ): UserStatus {
	switch (true) {
		case anonymous:
			return UserStatus.ANONYMOUS;
		case registeredAt && !anonymous:
			return UserStatus.REGISTERED;
		case invited && !registeredAt:
			return UserStatus.PENDING;
		case !invited && !emailInvalid && !registeredAt:
			return UserStatus.NOT_INVITED;
		default: return null
	}
}

/**
 * A factory function that creates Users
 */
export function createUser(params: Partial<UserFromServer>) {
	return {
		id: params.id,
		firstName: params.first_name,
		lastName: params.last_name,
		name: params.name,
		email: params.email,
		photo: params.photo,
		phone: params.phone,
		bio: params.bio,
		web: params.web,
		// speaker or user
		facebook: params.facebook || params.facebook_link,
		twitter: params.twitter || params.twitter_link,
		linkedIn: params.linkedIn || params.linkedIn_link,
		position: params.position,
		company: params.company,
		country: params.country,
		language: params.language,
		secretNumber: params.secret_number,
		lastEvents: params.last_events,
		emailLanguage: params.email_language,
		thumbnail: params.thumbnail,
		fbUser: params.fb_user,
		twUser: params.tw_user,
		liUser: params.li_user,
		isAnonymous: params.is_anonymous || params.anonymous,
		role: params.pivot?.role || params.role || UserRoles.VISITOR,
		roleType: getUserRoleType(params.role, params.pending, params.imported),
		pending: params.pending,
		imported: params.imported,
		appsumo: params.appsumo,
		payment: params.payment ? createUserPayment(params.payment) : null,
		defaultCompanyId: params.default_company_id,
		emailValid: params.email_valid,
		order: params.order ?? 0,
		registeredAt: dateTimeFromServer(params.registered_at),
		subscribed: params.subscribed,
		invited: params?.invited,
		emailInvalid: params.email_invalid,
		groupId: params.group_id,
		status: getUserStatus(params.email_invalid, params.anonymous, dateTimeFromServer(params.registered_at), params.invited)
	} as User;
}

/**
 * A factory function that creates Users
 */
export function createUserToServer(params: Partial<User>) {
	return {
		id: params.id,
		first_name: params.firstName,
		last_name: params.lastName,
		name: params.name,
		email: params.email,
		photo: params.photo,
		phone: params.phone,
		bio: params.bio,
		web: params.web,
		// for speaker
		facebook: params.facebook,
		twitter: params.twitter,
		linkedIn: params.linkedIn,
		// for user
		facebook_link: params.facebook,
		twitter_link: params.twitter,
		linkedIn_link: params.linkedIn,
		position: params.position,
		company: params.company,
		country: params.country,
		language: params.language,
		email_language: params.emailLanguage,
		thumbnail: params.thumbnail,
	} as UserFromServer;
}

export function createUserPayment(params: Partial<UserPaymentFromServer>): UserPayment {
	return {
		membership: params.membership,
		paidOn: dateTimeFromServer(params.paid_on),
		product: params.product
	} as UserPayment;
}

export function createUserAuthInfo(params: Partial<UserAuthInfoFromServer>): UserAuthInfo {
	return {
		token: params.token,
		userId: params.user_id
	} as UserAuthInfo;
}
